import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Box, Link, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "90px 0 30px 0",
	"md-padding": "60px 0 30px 0",
	"quarkly-title": "Footer-15"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"margin": "0px 0px 50px 0px",
			"grid-template-columns": "repeat(4, 1fr)",
			"grid-gap": "36px 34px",
			"lg-grid-template-columns": "repeat(3, 1fr)",
			"lg-flex-wrap": "wrap"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"width": "25%",
			"padding": "0px 50px 0px 0",
			"md-width": "90%",
			"sm-margin": "40px 0px 0px 0",
			"sm-width": "100%",
			"sm-padding": "0px 0px 0px 0",
			"margin": "0px 0 0px 0px",
			"lg-width": "100%",
			"lg-padding": "0px 0 0px 0"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "normal 500 18px/1.5 --fontFamily-sans",
			"letter-spacing": "1px",
			"children": "Net Masters Tennis Hub"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0 0px",
			"font": "normal 300 16px/1.5 --fontFamily-sansTrebuchet",
			"color": "#5a5d64",
			"lg-max-width": "640px",
			"children": "Améliorez votre jeu, profitez du voyage"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"margin": "0px 0 0px 0px",
			"width": "25%",
			"padding": "0px 0 0px 0px",
			"lg-width": "30%",
			"md-width": "45%",
			"sm-width": "100%"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "normal 500 18px/1.5 --fontFamily-sans",
			"letter-spacing": "1px",
			"children": "Contactez-nous"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 5px 0px",
			"font": "normal 300 16px/1.5 --fontFamily-sansTrebuchet",
			"color": "#5a5d64",
			"children": "Téléphone"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "tel:+33 6 62 59 20 70",
			"color": "#5a5d64",
			"text-decoration-line": "initial",
			"font": "normal 300 16px/1.5 --fontFamily-sansTrebuchet",
			"display": "inline-block",
			"margin": "0px 0px 15px 0px",
			"hover-color": "--primary",
			"children": "+33 6 62 59 20 70"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 5px 0px",
			"font": "normal 300 16px/1.5 --fontFamily-sansTrebuchet",
			"color": "#5a5d64",
			"children": "E-mail"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"href": "mailto:contact@alpineadventuresfr.com",
			"color": "#5a5d64",
			"text-decoration-line": "initial",
			"font": "normal 300 16px/1.5 --fontFamily-sansTrebuchet",
			"display": "inline-block",
			"margin": "0px 0px 15px 0px",
			"hover-color": "--primary",
			"children": "contact@alpineadventuresfr.com"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"margin": "0px 0 0px 0px",
			"width": "25%",
			"lg-width": "30%",
			"md-width": "45%",
			"sm-width": "100%"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "normal 500 18px/1.5 --fontFamily-sans",
			"letter-spacing": "1px",
			"children": "Adresse"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "normal 300 16px/1.5 --fontFamily-sansTrebuchet",
			"color": "#5a5d64",
			"children": "190 Rte de Cannes, 06130 Grasse, France"
		}
	},
	"text7": {
		"kind": "Text",
		"props": {
			"color": "#5a5d64",
			"text-decoration-line": "initial",
			"font": "normal 300 16px/1.5 --fontFamily-sansTrebuchet",
			"display": "block",
			"margin": "0px 0px 15px 0px",
			"children": "Du lundi au vendredi (10h – 18h)"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"align-items": "center",
			"justify-content": "space-between",
			"md-align-items": "flex-start",
			"md-flex-direction": "column"
		}
	},
	"text8": {
		"kind": "Text",
		"props": {
			"font": "--base",
			"color": "#5a5d64",
			"md-margin": "0px 0px 0 0px",
			"width": "30%",
			"md-width": "100%",
			"margin": "0 0px 0 0px",
			"children": "© 2024 Tous droits réservés."
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"align-items": "flex-start",
			"margin": "0px 0px 0px -",
			"justify-content": "flex-start",
			"display": "grid",
			"lg-margin": "0px 0px 0px 0px",
			"flex-direction": "row",
			"flex-wrap": "wrap",
			"md-margin": "0px 0px 25px 0px",
			"sm-flex-direction": "column",
			"md-order": "-1",
			"grid-template-columns": "repeat(3, 1fr)",
			"grid-gap": "0 34px"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"lg-margin": "0px 6px 0px 0px",
			"display": "flex",
			"href": "/index",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"text-decoration-line": "initial",
			"color": "#5a5d64",
			"hover-color": "--primary",
			"sm-padding": "0 0 15px 0",
			"children": "Principal"
		}
	},
	"link3": {
		"kind": "Link",
		"props": {
			"padding": "0 0 0 0",
			"margin": "0px 0 0px 0",
			"hover-color": "--primary",
			"lg-margin": "0px 6px 0px 0px",
			"href": "/services",
			"text-decoration-line": "initial",
			"color": "#5a5d64",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"display": "flex",
			"children": "Nos services"
		}
	},
	"link4": {
		"kind": "Link",
		"props": {
			"padding": "0 0 0 0",
			"margin": "0px 0 0px 0",
			"hover-color": "--primary",
			"lg-margin": "0px 6px 0px 0px",
			"href": "/faq",
			"text-decoration-line": "initial",
			"color": "#5a5d64",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"display": "flex",
			"children": "FAQ"
		}
	}
};

const Footer = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Text {...override("text")} />
				<Text {...override("text1")} />
			</Box>
			<Box {...override("box2")}>
				<Text {...override("text2")} />
				<Text {...override("text3")} />
				<Link {...override("link")} />
				<Text {...override("text4")} />
				<Link {...override("link1")} />
			</Box>
			<Box {...override("box3")}>
				<Text {...override("text5")} />
				<Text {...override("text6")} />
				<Text {...override("text7")} />
			</Box>
		</Box>
		<Box {...override("box4")}>
			<Text {...override("text8")} />
			<Box {...override("box5")}>
				<Link {...override("link2")} />
				<Link {...override("link3")} />
				<Link {...override("link4")} />
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Footer, { ...Section,
	defaultProps,
	overrides
});
export default Footer;